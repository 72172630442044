const splitFactory = require('@splitsoftware/splitio');
const logger = require('@exp/exp-utils/helper/logger');
const browserHelper = require('@exp/exp-utils/helper/browser');
const clientConfig = require('./clientConfig');
const arrayHelper = require('@exp/exp-utils/helper/array');

let _sdkClients = {};
let _sdkFactory;

const _excludedLabels = [
  'definition not found',
  'not in split'
];

const _logImpression = (data) => {
  if (data && data.impression && !arrayHelper.contains(_excludedLabels, data.impression.label.toLowerCase())) {
    const window = browserHelper.getWindow();
    window._expDataLayer = window._expDataLayer || [];
    window._expDataLayer.push({
      schema: 'add_experiment_assignment',
      type: 'abn',
      sinks: ['GA'],
      data: {
        experiment_id: data.impression.feature,
        variant_id: data.impression.treatment,
        experiment_source: 'SplitIO'
      }
    });
  }
};

const _getSdkClient = (trafficType) => {
  if (!_sdkClients[trafficType] || !_sdkClients[trafficType]._gd_sdk_ready) {
    logger.error('SplitIO SDK is being used before it is ready.');
  }
  return _sdkClients[trafficType];
};

class SplitIoClient {

  constructor(trafficType) {
    this.trafficType = trafficType;
  }

  /**
  * @public  splitio.getTreatment - requests a treatmeant from splitio for the user

  *
  * @param {String} splitName  - The name of the split treatment is being requested for
  * @param {Object} attributes - An object of target base attributes.
  *                              https://docs.split.io/docs/custom-attributes
  * @returns {String}
  **/
  getTreatment(splitName, attributes) {
    if (!splitName || typeof splitName !== 'string') {
      logger.error('Split name must be provided and a string');
      return;
    }

    // Request treatment
    return _getSdkClient(this.trafficType).getTreatment(splitName, attributes);
  }

  /**
  * @public  splitio.getTreatmentWithConfig - requests a treatment and associated config from splitio.
  *
  * @param {String} splitName  - The name of the split treatment that is being requested.
  * @param {Object} attributes - An object of target base attributes.
  *                              https://docs.split.io/docs/custom-attributes
  * @returns {Object} - The returned object will have two properties, treatment and config.
  *                     Config will be null if no config properties are found. Example:
  *                     {treatment: 'off', config: {properties: 'set', in: 'split UI'}}
  **/
  getTreatmentWithConfig(splitName, attributes) {
    if (!splitName || typeof splitName !== 'string') {
      logger.error('Split name must be provided and a string');
      return;
    }

    // Request treatment
    return _getSdkClient(this.trafficType).getTreatmentWithConfig(splitName, attributes);
  }

  destroy() {
    // Destroy returns a promise. Therefore, set the reference to
    // null and return the promise for consumers to chain
    if (typeof _getSdkClient(this.trafficType) !== 'undefined') {
      return _getSdkClient(this.trafficType).destroy().then(() => {
        delete _sdkClients[this.trafficType];
      });
    }
  }
}

const _loadSdkClient = (apiKey, trafficType, userId) => {
  if (!_sdkFactory) {
    // If the factory is undefined, this is the first time trying to initialize a SplitIO client
    // Create a new client using the SplitIO config to set the userId / trafficType
    const config = clientConfig.getConfig(apiKey, trafficType, userId, _logImpression);
    _sdkFactory = splitFactory.SplitFactory(config); // eslint-disable-line
    _sdkClients[trafficType] = _sdkFactory.client();
  } else if (!_sdkClients[trafficType]) {
    // Since the factory has already been initialized, we do not need to update the config
    _sdkClients[trafficType] = _sdkFactory.client(userId, trafficType);
  }
};

const init = (apiKey, trafficType, userId, callback) => {
  // Starts the initialization of a SDK client for a particular traffic type
  _loadSdkClient(apiKey, trafficType, userId);

  // If the client is ready, return our client wrapper
  if (_sdkClients[trafficType]._gd_sdk_ready) {
    return callback(new SplitIoClient(trafficType));
  }

  // Since the client is not yet ready, add a hook for the SDK_READY event.
  _sdkClients[trafficType].on(_sdkClients[trafficType].Event.SDK_READY, function () {
    // Flag the client as ready in our lookup object
    _sdkClients[trafficType]._gd_sdk_ready = true;
    logger.info('SplitIO SDK is loaded and ready for use');

    // Return our client wrapper
    return callback(new SplitIoClient(trafficType));
  });

  // this callback will be called after 1.5 seconds if and only if the client
  // is not ready for that time. You can still call getTreatment()
  // but it could return CONTROL.
  _sdkClients[trafficType].on(_sdkClients[trafficType].Event.SDK_READY_TIMED_OUT, function () {
    logger.error('SplitIO SDK failed to load before timeout');
  });
};

let internalExports = {};
if (process.env.NODE_ENV !== 'production') {
  // Used for unit testing
  internalExports = {
    _destroySdkClients() {
      _sdkClients = {};
    },
    _destroySdkFactory() {
      _sdkFactory = undefined;
    },
    SplitIoClient: SplitIoClient,
    _getSdkClient: _getSdkClient,
    _logImpression: _logImpression
  };
}

internalExports.init = init;

module.exports = internalExports;
